module.exports = {
    prefix: 'tw-',
    content: [
        './assets/src/js/**/*.js',
        './templates/**/*.html.twig',
        './src/Form/Type/**/*.php',
        './vendor/acc-digital/**/*.html.twig'
    ],
    safelist: process.env.NODE_ENV === 'development' ? [{pattern: /.*/}] : [
        'tw-animate-fadeIn'
    ],
    darkMode: 'class',
    theme: {
        colors: {
            transparent: 'transparent',
            current: 'currentColor',
            brand: {
                50: '#F6F3EE',
                100: '#EDE7DE',
                300: '#C9B69C',
                500: '#A68659',
                700: '#745E3E',
                900: '#423624',
            },
            logo: {
                gray: {
                    200: '#D0D1DB',
                    300: '#A7A9B4',
                    600: '#545860',
                }
            },
            blue: '#1fb6ff',
            'special-yellow': '#E49B00',
            green: '#5E9F37',
            yellow: '#FCD342',
            white: '#fff',
            black: '#000',
            gray: {
                40: '#F1F1F1',
                50: '#F2F2F2',
                100: '#E6E6E6',
                300: '#B3B3B3',
                500: '#808080',
                700: '#595959',
                900: '#333333',
            },
            shadow: {
                10: 'rgb(0, 0, 0, 0.10)',
                30: 'rgb(0, 0, 0, 0.30)',
                50: 'rgb(0, 0, 0, 0.50)',
                70: 'rgb(0, 0, 0, 0.70)',
            },
            red: '#E71D36',
            gradient: '#1A1A1A'
        },
        screens: {
            xs: '380px',
            sm: '480px',
            md: '768px',
            lg: '1024px',
            xl: '1280px',
            xxl: '1440px',
            xxxl: '1680px',
            max: '1920px',
        },
        container: {
            center: true,
            padding: '1rem',
            screens: {
                xs: '100%',
                sm: '100%',
                md: '768px',
                lg: '1024px',
                xl: '1280px',
                '2xl': '1440px',
            },
        },
        fontFamily: {
            roboto: ['Roboto', 'sans-serif']
        },
        extend: {
            width: {
                '4.5': '1.125rem',
                '30': '7.5rem',
                '35': '8.75rem',
            },
            height: {
                '4.5': '1.125rem',
            },
            maxWidth: {
                '60': '60rem',
            },
            margin: {
                '18': '4.5rem',
            },
            padding: {
                '4.5': '1.125rem',
                '18': '4.5rem',
            },
            fontSize: {
                '14': ['0.875rem', {lineHeight: '1.2rem'}],
                '16': ['1rem', {lineHeight: '1.5rem'}],
                '20': ['1.25rem', {lineHeight: '1.9rem'}],
                '24': ['1.5rem', {lineHeight: '2rem'}],
                '32': ['2rem', {lineHeight: '2.3rem'}],
                '40': ['2.5rem', {lineHeight: '2.875rem'}],
                '56': ['3.5rem', {lineHeight: '3.8rem'}],
                '64': ['4rem', {lineHeight: '4.3rem'}],
                '80': ['5rem', {lineHeight: '5.3rem'}],
            },
            letterSpacing: {
                '07': '0.07em',
            },
            aspectRatio: {
                'image': '4 / 3',
                '2/1': '2 / 1',
                '3/2': '3 / 2',
                '4/3': '4 / 3',
                '4/5': '4 / 5',
                '5/2': '5 / 2',
                '6/5': '6 / 5',
                '12/7': '12 / 7',
                'unset': 'unset',
            },
            boxShadow: {
                'configurator': '0px 4px 12px rgba(0, 0, 0, 0.35)',
                'configuratorBtn': '0px 4px 8px rgba(0, 0, 0, 0.3)',
                'material': '0px 8px 24px rgba(0, 0, 0, 0.4)'
            },
            borderRadius: {
                'xs': '0.0625rem',
            },
            dropShadow: {
                'imageHotspot': '0px 8px 32px rgba(0, 0, 0, 0.4)'
            },
            keyframes: {
                'fadeIn': {
                    '0%': {
                        display: 'none',
                        opacity: '0'
                    },
                    '1%': {
                        display: 'block',
                        opacity: '0'
                    },
                    '100%': {
                        display: 'block',
                        opacity: '1'
                    },
                },
            },
            animation: {
                'fadeIn': 'fadeIn .3s ease-out',
            }
        }
    },
    plugins: [],
};
