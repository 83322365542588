import { isEditMode } from '../../../assets/src/js/utils';
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../../tailwind.config.js'

export default class Index {
    constructor(rootNode, props) {
        if (isEditMode()) {
            return;
        }

        this.$refs = {
            rootNode,
            slides: rootNode.querySelectorAll('.slider-item'),
        };

        this.openId = null;

        [...this.$refs.slides].forEach(slide => {
            const items = slide.querySelectorAll('.hotspot');
            items.forEach(node => {
                node.content = node.querySelector('.hotspotContent');
                node.addEventListener('click', this.toggleItem.bind(this));
            });
        });
    }

    toggleItem(event) {
        const id = event.currentTarget.getAttribute('data-hotspot');

        if (this.openId !== null && this.openId !== id) {
            [...this.$refs.rootNode.querySelectorAll('.hotspot-' + this.openId)].forEach(hotspot => {
                if (hotspot.classList.contains('open')) {
                    hotspot.classList.remove('open');
                    this.openId = null;
                    this.resetStyles(hotspot);
                }
            })
        }

        [...this.$refs.rootNode.querySelectorAll('.hotspot-' + id)].forEach(hotspot => {
            if (hotspot.classList.contains('open')) {
                hotspot.classList.remove('open');
                this.openId = null;
                this.resetStyles(hotspot);
            } else {
                hotspot.classList.add('open');
                this.openId = id;
                
                if (hotspot.getAttribute('data-hasImage')) {
                    this.setAspectRatio(hotspot);
                }
                
                this.handleContainerConflicts(hotspot);
            }
        })
    }

    setAspectRatio(hotspot) {
        const aspectRatio = hotspot.getAttribute('data-aspect-ratio');
        if(!aspectRatio) return;

        const fullConfig = resolveConfig(tailwindConfig);
        const breakpoint = parseInt(fullConfig.theme.screens.md.replace('px', ''));
        if (aspectRatio < 0.85 && window.innerWidth >= breakpoint) {
            let correctedAspectRatio = aspectRatio - (aspectRatio < 0.6 ? 0.005 : 0.03); // small correction to fit the image better
            hotspot.style.aspectRatio = String(correctedAspectRatio) + " / 1";
        }
    }

    async handleContainerConflicts(hotspot) {
        await this.sleep(); // wait for the DOM to update

        const hotspotLeft = hotspot.offsetLeft;
        const hotspotTop = hotspot.offsetTop;
        const hotspotWidth = hotspot.offsetWidth;
        const hotspotHeight = hotspot.offsetHeight;

        const container = this.$refs.rootNode.querySelector('.hotspotContainer');
        const containerWidth = container.offsetWidth;
        const containerHeight = container.offsetHeight;

        let horizontalConflict = false;
        let verticalConflict = false;

        // Check if the hotspot is outside the container
        if (hotspotLeft - hotspotWidth / 2 < 15) {
            hotspot.style.left = "15px";
            horizontalConflict = true;
        } else if (hotspotLeft + hotspotWidth / 2 > containerWidth-15) {
            hotspot.style.left = "auto";
            hotspot.style.right = "15px";
            horizontalConflict = true;
        }

        if (hotspotTop < 15) {
            hotspot.style.top = "15px";
            verticalConflict = true;
        } else if (hotspotTop + hotspotHeight > containerHeight-15) {
            hotspot.style.top = "auto";
            hotspot.style.bottom = "15px";
            verticalConflict = true;
        }

        if (horizontalConflict && verticalConflict) {
            hotspot.style.transform = "translate(0, 0)";
        } else if (horizontalConflict) {
            hotspot.style.transform = "translate(0, -50%)";
        } else if (verticalConflict) {
            hotspot.style.transform = "translate(-50%, 0)";
        }
    }

    resetStyles(hotspot) {
        hotspot.style.aspectRatio = "";
        hotspot.style.top = hotspot.getAttribute('data-top') + "%";
        hotspot.style.left = hotspot.getAttribute('data-left') + "%";
        hotspot.style.right = "auto";
        hotspot.style.bottom = "auto";
        hotspot.style.transform = "";
    }

    /**
     * Wait for the next animation frame
     * @returns {Promise} A promise that resolves after the next animation frame
     */
    sleep() { 
        return new Promise(requestAnimationFrame); 
    }
}
