import { MarkerClusterer } from "@googlemaps/markerclusterer";
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../tailwind.config.js';

export default class GoogleMap {
    constructor(rootNode) {
        this.$refs = {
            rootNode,
        };
    }
};

window.initMap = function(mapId, params = null, callbacks = []) {
    try {
        const mapDiv = document.getElementById(mapId)

        if (!params) params = {
            center: {lat: 0, lng: 0},
            zoom: 10
        }

        window['maps'+mapId] = new google.maps.Map(mapDiv, params);

        callbacks.forEach(callback => {
            eval(callback + '()')
        })
    } catch(e) {
        console.warn(e.message)
    }
}

window.MarkerClusterer = MarkerClusterer;
window.TailwindFullConfig = resolveConfig(tailwindConfig)