import { validate, submit } from '../../../assets/src/js/utils';

export default class PartnerSearch {
    constructor(rootNode) {
        this.$refs = {
            rootNode,
            form: rootNode.querySelector('form'),
            inputs: rootNode.querySelectorAll('input, textarea, select'),
            groupSelection: rootNode.querySelector('select#partner_search_private_contactGroup'),
            checkboxes: rootNode.querySelectorAll('#partner_search_private_types input[type="checkbox"]'),
            checkboxContainer: rootNode.querySelector('#partner_search_private_types'),
            checkOnChange: false,
        };

        const isPrivateSearch = this.$refs.rootNode.hasAttribute('data-private');
        
        if(isPrivateSearch){
            this.initFacets();
            this.initRequirements();
        }else{
            this.$refs.rootNode.addEventListener('change', validate.bind(this))
            this.$refs.rootNode.addEventListener('submit', submit.bind(this))
        }
    }

    initRequirements(){
        this.$refs.missingTypeError = document.createElement('p');
        this.$refs.missingTypeError.innerHTML = this.$refs.rootNode.getAttribute('data-type-required-translation');
        this.$refs.missingTypeError.classList.add('tw-text-brand-500');
        this.$refs.missingTypeError.classList.add('tw-mt-1');
        this.$refs.missingTypeError.classList.add('tw-hidden');

        const label = this.$refs.rootNode.querySelector('#partner_search_private_types_row > label');

        label.appendChild(this.$refs.missingTypeError);

        this.$refs.form.addEventListener('submit', (e) => {
            if(!this.anyCheckboxChecked()){
                this.displayMissingTypeError()
                e.preventDefault();
                return;
            }

            this.hideMissingTypeError();
        });
    }

    anyCheckboxChecked(){
        for (let i = 0; i < this.$refs.checkboxes.length; i++) {
            if(this.$refs.checkboxes[i].checked){
                return true;
            }
        }

        return false;
    }

    displayMissingTypeError(){
        this.$refs.missingTypeError.classList.remove('tw-hidden');
    }

    hideMissingTypeError(){
        this.$refs.missingTypeError.classList.add('tw-hidden');
    }

    initFacets(){
        this.$refs.noOptionsInfo = document.createElement('div');
        this.$refs.noOptionsInfo.innerHTML = this.$refs.rootNode.getAttribute('data-no-options-translation');
        this.$refs.noOptionsInfo.classList.add('tw-text-gray-500');
        this.$refs.checkboxContainer.appendChild(this.$refs.noOptionsInfo);

        this.updateFacets();

        this.$refs.rootNode.querySelectorAll('.hide-until-initialized').forEach(elem => elem.classList.remove('tw-hidden'));
        
        this.$refs.groupSelection.addEventListener('change', () => {
            this.updateFacets();
        })
    }

    updateFacets(){
        this.hideAllCheckboxes();
        const currentId = this.$refs.groupSelection.value;

        let count = 0;

        this.$refs.checkboxes.forEach(checkbox => {
            const validIds = checkbox.getAttribute('data-facet-groups').split(',');

            if(validIds && validIds.includes(currentId)){
                checkbox.parentElement.classList.remove('tw-hidden')
                count++;
            }else{
                checkbox.checked = false;
            }
        })

        if(count > 0){
            this.hideNoOptionsInfo()
        }else{
            this.showNoOptionsInfo()
        }
    }

    hideAllCheckboxes(){
        this.$refs.checkboxes.forEach(checkbox => {
            checkbox.parentElement.classList.add('tw-hidden')
        })
    }

    showNoOptionsInfo(){
        this.$refs.noOptionsInfo.classList.remove('tw-hidden')
    }

    hideNoOptionsInfo(){
        this.$refs.noOptionsInfo.classList.add('tw-hidden')
    }
};
