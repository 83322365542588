gdprLoadMap();

function gdprLoadMap() {
    if (localStorage.getItem('mapGdpr') == 1) {
        const cookies = {
            'media': 'yes'
        }

        window.addEventListener('load', function () {
            window.dispatchEvent(new CustomEvent('accConsentReady', {detail: {layers: cookies}}));
        })
    }
}