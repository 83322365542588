import { validate, submit } from '../../../assets/src/js/utils';

export default class ContactForm {
    constructor(rootNode) {
        this.$refs = {
            rootNode,
            form: rootNode.querySelector('form'),
            alert: rootNode.querySelector('.alert-anchor'),
            contactType: rootNode.querySelector('#contact_contactType'),
            contactDate: rootNode.querySelector('#contact_date'),
            altDate: rootNode.querySelector('#contact_alternateDate'),
            inputs: rootNode.querySelectorAll('input, textarea, select'),
            checkOnChange: false
        };

        this.toggleContactType()
        this.$refs.rootNode.addEventListener('change', this.toggleContactType.bind(this))
        this.$refs.rootNode.addEventListener('change', validate.bind(this))
        this.$refs.rootNode.addEventListener('submit', submit.bind(this))

        // Scroll to alert
        if(this.$refs.alert) {
            this.$refs.alert.scrollIntoView()
        }
    }

    toggleContactType() {
        const value =  this.$refs.contactType?.value || null

        if(value) {
            this.$refs.rootNode.setAttribute('data-contact-type', value)

            const contactDate = this.$refs.contactDate
            const altDate = this.$refs.altDate

            if (value === 'appointment') {
                contactDate.closest('.form-row').classList.remove('tw-hidden')
                contactDate.setAttribute('required', 'required')

                altDate.closest('.form-row').classList.remove('tw-hidden')
                altDate.setAttribute('required', 'required')
            } else {
                contactDate.closest('.form-row').classList.add('tw-hidden')
                contactDate.removeAttribute('required')

                altDate.closest('.form-row').classList.add('tw-hidden')
                altDate.removeAttribute('required')
            }
        }
    }
};
