import Alpine from 'alpinejs'
import Swiper from 'swiper/bundle'
import axios from 'axios'
import Cookies from 'js-cookie'
import lottieWeb from 'lottie-web';

// Plugins
window.Swiper = Swiper
window.axios = axios
window.cookies = Cookies
window.lottie = lottieWeb

// Add to window
window.Alpine = Alpine;
window.Alpine.start();