import { validate, submit } from '../../../assets/src/js/utils';

export default class ApplicationForm {
    constructor(rootNode) {
        this.$refs = {
            rootNode,
            form: document.querySelector('form'),
            inputs: document.querySelectorAll('input, textarea, select'),
            alert: rootNode.querySelector('.alert-anchor'),
            checkOnChange: false
        };

        this.$refs.rootNode.addEventListener('change', validate.bind(this))
        this.$refs.rootNode.addEventListener('submit', submit.bind(this))

        // Scroll to alert
        if(this.$refs.alert) {
            this.$refs.alert.scrollIntoView()
        }
    }
};
